.custom-switch {
    padding-left: 2.25rem;
}
.custom-control {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    left: 2rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
[type="checkbox"]:before {
    content: "";
    position: absolute;
    left: -1.2em;
    top: -0.9em;
    display: inline-block;
    width: 15px;
    height: 16px;
    border: 2px solid #333333;
    border-radius: 2px 8px 2px 4px / 5px 3px 5px 3px;
}

[type="checkbox"]:checked:after {
    content: "x";
    position: absolute;
    left: -0.64em;
    top: -0.48em;
    font-size: 1.5rem;
    line-height: 0.5;
    color: #333333;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-weight: bold;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #333333;
    background-color: #333333;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #333333 solid 2px;
}

.custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 2.75rem;
    pointer-events: all;
    border-radius: 7rem 8rem 8rem 8rem / 4rem 5rem 6rem 6rem;
    top: 0rem;
    height: 1.75rem;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}
.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 4px);
    width: calc(1.5rem - 8px);
    height: calc(1.5rem - 8px);
    background-color: #333333;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1.25rem);
    transform: translateX(1.25rem);
}
